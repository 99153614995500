exports.components = {
  "component---src-pages-adventure-js": () => import("./../../../src/pages/adventure.js" /* webpackChunkName: "component---src-pages-adventure-js" */),
  "component---src-pages-almora-js": () => import("./../../../src/pages/almora.js" /* webpackChunkName: "component---src-pages-almora-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-beyond-travel-js": () => import("./../../../src/pages/beyond-travel.js" /* webpackChunkName: "component---src-pages-beyond-travel-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-char-dham-js": () => import("./../../../src/pages/char-dham.js" /* webpackChunkName: "component---src-pages-char-dham-js" */),
  "component---src-pages-chopta-js": () => import("./../../../src/pages/chopta.js" /* webpackChunkName: "component---src-pages-chopta-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corbett-js": () => import("./../../../src/pages/corbett.js" /* webpackChunkName: "component---src-pages-corbett-js" */),
  "component---src-pages-culture-js": () => import("./../../../src/pages/culture.js" /* webpackChunkName: "component---src-pages-culture-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-ganga-darshan-js": () => import("./../../../src/pages/ganga-darshan.js" /* webpackChunkName: "component---src-pages-ganga-darshan-js" */),
  "component---src-pages-garhwal-js": () => import("./../../../src/pages/garhwal.js" /* webpackChunkName: "component---src-pages-garhwal-js" */),
  "component---src-pages-get-away-js": () => import("./../../../src/pages/get-away.js" /* webpackChunkName: "component---src-pages-get-away-js" */),
  "component---src-pages-haridwar-js": () => import("./../../../src/pages/haridwar.js" /* webpackChunkName: "component---src-pages-haridwar-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kedar-travels-js": () => import("./../../../src/pages/kedar-travels.js" /* webpackChunkName: "component---src-pages-kedar-travels-js" */),
  "component---src-pages-kumaon-js": () => import("./../../../src/pages/kumaon.js" /* webpackChunkName: "component---src-pages-kumaon-js" */),
  "component---src-pages-kumbh-mela-js": () => import("./../../../src/pages/kumbh-mela.js" /* webpackChunkName: "component---src-pages-kumbh-mela-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-nainital-js": () => import("./../../../src/pages/nainital.js" /* webpackChunkName: "component---src-pages-nainital-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-punches-js": () => import("./../../../src/pages/punches.js" /* webpackChunkName: "component---src-pages-punches-js" */),
  "component---src-pages-rafting-js": () => import("./../../../src/pages/rafting.js" /* webpackChunkName: "component---src-pages-rafting-js" */),
  "component---src-pages-ranikhet-js": () => import("./../../../src/pages/ranikhet.js" /* webpackChunkName: "component---src-pages-ranikhet-js" */),
  "component---src-pages-religious-js": () => import("./../../../src/pages/religious.js" /* webpackChunkName: "component---src-pages-religious-js" */),
  "component---src-pages-retreat-js": () => import("./../../../src/pages/retreat.js" /* webpackChunkName: "component---src-pages-retreat-js" */),
  "component---src-pages-rishikesh-js": () => import("./../../../src/pages/rishikesh.js" /* webpackChunkName: "component---src-pages-rishikesh-js" */),
  "component---src-pages-safari-js": () => import("./../../../src/pages/safari.js" /* webpackChunkName: "component---src-pages-safari-js" */),
  "component---src-pages-scenic-js": () => import("./../../../src/pages/scenic.js" /* webpackChunkName: "component---src-pages-scenic-js" */),
  "component---src-pages-spa-js": () => import("./../../../src/pages/spa.js" /* webpackChunkName: "component---src-pages-spa-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-trekking-js": () => import("./../../../src/pages/trekking.js" /* webpackChunkName: "component---src-pages-trekking-js" */),
  "component---src-pages-wellness-js": () => import("./../../../src/pages/wellness.js" /* webpackChunkName: "component---src-pages-wellness-js" */),
  "component---src-pages-yoga-js": () => import("./../../../src/pages/yoga.js" /* webpackChunkName: "component---src-pages-yoga-js" */)
}

